<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-popup classContent="popup-example" title="Nouveau Quartier" :active.sync="popupQtier">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-select autocomplete label="Ville" class="w-full" v-model="ville" >
                <vs-select-item v-for="(item, index) in villeData" :key="index" :value="item.libelleVille" :text="item.libelleVille"/>
              </vs-select>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle" v-validate="'required'" name="libelleQtier" v-model="libelleQtier" />
              <span class="text-danger text-sm"  v-show="errors.has('libelleQtier')">{{ errors.first('libelleQtier') }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="createQuartier" :disabled="!validateForm">Enregistrer</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="libelleQtier = ''; ">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <vs-popup classContent="popup-example" title="Modifier un quartier" :active.sync="popupQtierUpdate">

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-select autocomplete label="Ville" class="w-full" v-model="ville" >
                <vs-select-item v-for="(item, index) in villeData" :key="index" :value="item.libelleVille" :text="item.libelleVille"/>
              </vs-select>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle" v-validate="'required'" name="libelleQtier" v-model="libelleQtier" />
              <span class="text-danger text-sm"  v-show="errors.has('libelleQtier')">{{ errors.first('libelleQtier') }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="updateQtier" :disabled="!validateForm">Modifier</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="libelleQtier = '';  popupQtierUpdate = false;">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="AlignJustifyIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Liste de ville</span>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" pagination max-items="10" search :data="quartierData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="popupQtier=true">Nouveau quartier</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="_id">
                  N°
                </vs-th>
                <vs-th sort-key="ville">
                  Ville
                </vs-th>
                <vs-th sort-key="libelleQtier">
                  Quartier
                </vs-th>
                <vs-th sort-key="website">
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].ville">
                    {{data[indextr].ville}}
                  </vs-td>

                  <vs-td :data="data[indextr].libelleQtier">
                    {{data[indextr].libelleQtier}}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateQtierShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupQtier: false,
      popupQtierUpdate: false,
      // status: false,
      ville: '',
      libelleQtier: '',
      selected:[],
      villes:[],
      quartierId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.ville !== '' && this.libelleQtier !== ''
    },
    villeData () {
      return this.$store.state.pays.villes
    },
    quartierData () {
      return this.$store.state.pays.quartiers
    }
  },

  methods: {
    createQuartier () {
      const payload = {
        ville: this.ville,
        libelleQtier: this.libelleQtier
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('pays/createQuartier', payload)
        .then(() => {

          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })

          this.resete_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupQtier = false
        })
    },
    confirmDeleteRecord (data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le quartier "${data.libelleQtier}"`,
        accept: this.deleteRecord(data),
        acceptText: 'Supprimer'
      })
    },
    deleteRecord (data) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/dropQuartier', data._id)
        .then(() => {
          this.showDeleteSuccess(data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Supprimer',
        text: 'Le quartier selectionnée a bien été supprimé'
      })
    },
    getQuartiers () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/getQuartiers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    updateQtierShowForm (data) {
      this.ville = data.ville
      this.libelleQtier = data.libelleQtier
      this.quartierId = data._id
      this.popupQtierUpdate = true
    },
    updateQtier () {

      const payload = {

        ville: this.ville,
        libelleQtier: this.libelleQtier,
        id: this.quartierId
      }

      this.$store.dispatch('pays/updateQuartier', payload)
        .then(() => {

          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })

          this.resete_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupQtierUpdate = false
        })

    },
    resete_data () {
      this.ville = this.libelleQtier = ''
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {
    this.getQuartiers()
    this.getVilles()
  }
}
</script>

